import React from "react";
import Layout from "../../layouts/Layout";
import TopList from "../../components/TopList";
import { graphql, Link } from "gatsby";
import { Card, Col, Row } from "react-bootstrap";
import ArticleTeaser from "../../components/ArticleTeaser";
import PostList from "../../components/PostList";
import Pagination from "../../components/Pagination";
import Container from "react-bootstrap/Container";
import FooterSection from "../../components/FooterSection";

const BlogTemplate = ({ pageContext, data, location }) => {
  const featuredPost = data.featuredPost.edges[0].node;
  const allBlogPosts = data.allBlogPosts.edges;
  const newsCategory = data?.newsCategory;
  const stringTranslations = pageContext.stringTranslations;

  return (
    <Layout
      context={pageContext}
      className="blog-page"
      useContainer={false}
      location={location}
      showProgressbar={false}
      disableHrefLang={pageContext.pageNumber !== 0}
    >
      <Container>
        {!pageContext.isChildPage && (
          <Row className="blog-page__header">
            <Col lg={7}>
              <ArticleTeaser
                imageSrcSet={
                  featuredPost?.featured_media?.localFile?.childImageSharp?.fixed?.srcSet
                }
                imageSrc={
                  featuredPost?.featured_media?.localFile?.childImageSharp?.fixed?.src
                }
                excerpt={featuredPost.post_excerpt}
                link={featuredPost.path}
                title={featuredPost.post_title}
                date={featuredPost.post_date}
                variant="big"
                category={featuredPost?.post_categories[0]?.name}
              />
            </Col>
            {
              data.top5?.edges && data.top5.edges.length > 0 && (
                <Col lg={{ span: 3, offset: 1 }}>
                  <TopList title={stringTranslations["Most read"]} items={data.top5.edges} />
                </Col>
              )
            }
          </Row>
        )}

        <Row className="justify-content-center">
          <Col lg={6}>
            <PostList posts={allBlogPosts} withCategory />
          </Col>
        </Row>

        <Row>
          <Col lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
            <Pagination {...pageContext} />
          </Col>
        </Row>
      </Container>
      {newsCategory && (
        <FooterSection
          title={ stringTranslations["Amazon Affiliate News"] }
          linkText={ stringTranslations["All"] }
          linkTo={newsCategory.path}
        >
          {data.mostRecentNews.edges.map(post => (
            <Col
              sm={12}
              md={6}
              lg={4}
              className="d-flex align-items-stretch"
              key={post.node.post_title}
            >
              <Link to={post.node.path} className="d-flex w-100">
                <Card className="link card-news">
                  <Card.Body>
                    <Card.Text className="card-date">
                      {post.node.post_date}
                    </Card.Text>
                    <Card.Title>{post.node.post_title}</Card.Title>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </FooterSection>
      )}
    </Layout>
  );
};

export const query = graphql`
  query BlogQuery($locale: String!, $skip: Int!, $limit: Int!) {
    featuredPost: allWordpressPost(
      filter: { language: { locale: { eq: $locale } } }
      limit: 1
      sort: { fields: post_date, order: DESC }
    ) {
      edges {
        node {
          post_date(locale: $locale, formatString: "MMMM D, YYYY")
          ...BigPostTeaserFields
        }
      }
    }
    top5: allWordpressWpTop5(
        filter: { language: { locale: { eq: $locale } } }
    ) {
        edges {
            node {
                path
                post_title
            }
        }
    }
    allBlogPosts: allWordpressPost(
      filter: { language: { locale: { eq: $locale } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          post_date(locale: $locale, formatString: "MMMM D, YYYY")
          ...PostTeaserFields
        }
      }
    }
    mostRecentNews: allWordpressPost(
      filter: {
        post_categories: { elemMatch: { cat_name: { eq: "News" } } }
        language: { locale: { eq: $locale } }
      }
    ) {
      edges {
        node {
          ...PostTeaserFields
        }
      }
    }
    newsCategory: wordpressCategory(
      cat_name: { eq: "News" }
      language: { locale: { eq: $locale } }
      count: {gt: 0}
    ) {
      cat_name
      path
    }
  }
`;

export default BlogTemplate;
